import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import osIcon1 from "../assets/images/os-icon-1.svg";
import osIcon2 from "../assets/images/os-icon-2.svg";
import osIcon3 from "../assets/images/os-icon-3.svg";
import osIcon4 from "../assets/images/os-icon-4.svg";
import osIcon5 from "../assets/images/os-icon-5.svg";

import { API_URL } from "../config/constant";
import {
  SET_CURRENT_STEP,
  SET_SELECTED_CATEGORY,
  SET_ANSWERS,
  OVERALL_SCORE
} from "../store/actions";
import Loader from "./Loader";

export const Congratulations = (props) => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.userDetail);
  const [showLoader, setShowLoader] = useState(true);
  const [result, setResult] = useState({});
  const currentStep = useSelector((state) => state.currentStep);
  const overAllCategory = useSelector((state) => state.overAllCategory);
  const categoryList = useSelector((state) => state.categoryList);
  const answeredQuestionsList = useSelector(
    (state) => state.answeredQuestionsList
  );
  const isGutPrePostAssessment = useSelector((state) => state.isGutPrePostAssessment);
  const [average, setAverage] = useState(0);
  const [imgIcon, setImgIcon] = useState(null);
  const [bgColor, setBgColor] = useState(null);

  const [overAllScoreForPrePostAssessment, setOverAllScoreForPrePostAssessment] = useState(null);

  const [count, setCount] = useState(
    categoryList.length === 4
      ? categoryList.length - 2 + 5
      : categoryList.length === 5
        ? categoryList.length - 2 + 5
        : categoryList.length + 3
  );
  // console.log("count", count);
  // const [individualScore, setIndividualScore] = useState([]);
  const findIndex = answeredQuestionsList.findIndex(
    (a) => a.answer === "0" && a.isAdult === true
  );
  findIndex !== -1 && answeredQuestionsList.splice(findIndex, 1);

  useEffect(() => {
    // totalScore formula changes on 7/02/2022
    const individualScore = [];
    let categoryScore = null
    categoryList.map((element) => {
      const questions = answeredQuestionsList.filter(
        (obj) => obj.category === element.id
      );
      const totalScore = questions.reduce((acc, obj) => {
        if (obj.isMultipliable) {
          return acc + Number(obj.answer * 2);
        } else if (obj.isMultiselect) {
          return acc + obj.answer.reduce((innerAcc, curr) => innerAcc + Number(curr), 0);
        } else {
          return acc + Number(obj.answer);
        }
      }, 0);
      // const maxScore = questions.reduce(
      //   (acc, obj) => acc + Number(obj.maxScore),
      //   0
      // );
      const maxScore = questions.reduce((acc, obj) => {
        if (obj.isMultipliable) {
          return acc + Number(obj.maxScore * 2);
        } else if (obj.isMultiselect) {
          return acc + obj.answer.reduce((innerAcc, curr) => innerAcc + Number(curr), 0);
        } else {
          return acc + Number(obj.maxScore);
        }
      }, 0);
      const minScore = questions.reduce(
        (acc, obj) => acc + Number(obj.minScore),
        0
      );
      const overallScore =
        Math.floor(((totalScore - minScore) / (maxScore - minScore)) * 100) ||
        0;
      return individualScore.push(overallScore);
    });
    // Calculate the overall score outside the map function
    categoryScore = individualScore.length
      ? individualScore.reduce((acc, score) => acc + score, 0) / individualScore.length
      : 0;

    setOverAllScoreForPrePostAssessment(categoryScore)
    dispatch({ type: OVERALL_SCORE, value: categoryScore })

    isGutPrePostAssessment ? setAverage(categoryScore) :
    setAverage(
      categoryList.length === 4
        ? Math.floor(
          (individualScore[3] * 3 +
            individualScore[0] * 2 +
            individualScore[1] +
            individualScore[2]) /
          7
        )
        : categoryList.length === 5
          ? Math.floor(
            (individualScore[3] * 3 +
              individualScore[0] * 2 +
              individualScore[1] +
              individualScore[2] +
              individualScore[4]) /
            8
          )
          : Math.floor(
            (individualScore[3] * 3 +
              individualScore[0] * 2 +
              individualScore[1] +
              individualScore[2] +
              individualScore[4] +
              individualScore[5]) /
            9
          )
    );
    // eslint-disable-next-line
  }, [categoryList, answeredQuestionsList]);

  useEffect(() => {
    // if (average === 0) return;
    getResult();
    if (average <= 20) {
      setImgIcon(osIcon1);
      setBgColor("#00FFF6");
    } else if (average <= 40) {
      setImgIcon(osIcon2);
      setBgColor("#78E31B");
    } else if (average <= 60) {
      setImgIcon(osIcon3);
      setBgColor("#E68FFF");
    } else if (average <= 80) {
      setImgIcon(osIcon4);
      setBgColor("#FBB206");
    } else if (average <= 100) {
      setImgIcon(osIcon5);
      setBgColor("#FFEF0A");
    } else {
      setImgIcon(osIcon5);
      setBgColor("#FFEF0A");
    }
    // eslint-disable-next-line
  }, [average]);

  const getResult = async () => {
    // if (average === 0) return;
    try {
      const { data } = await axios.post(`${API_URL}/score/over-all`, {
        categoryId: overAllCategory?.id,
        // score: average === 0 ? 1 : average, // Baset changes
        score:  average, // Rizwan changes

      });

      setResult(data.result);
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) return <Loader />;

  return (
    <>
      {/* <h1>Congratulations</h1> */}
      <div className="congratulations-main" id="download">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div>
            <h1 className="page-title">
              <span>Congratulations!</span>
            </h1>
            <p className="sub-text">
              You have honestly and successfully completed this survey.
            </p>
          </div>
          <div className="btn-bdr-block text-end mt-2 mt-md-0 mb-2 mb-mb-0">
            <button
              type="button"
              onClick={() => {
                if (isGutPrePostAssessment) {
                  dispatch({ type: SET_CURRENT_STEP, value: 2 })
                } else {
                  dispatch({ type: SET_CURRENT_STEP, value: 1 });
                }
                dispatch({ type: SET_ANSWERS, value: [] });
              }}
              className="btn btn-bdr"
            >
              RETAKE SURVEY
            </button>
          </div>
        </div>
        <div className="d-flex mt-3 pb-2">
          <div className="overall-score d-flex">
            <div className="icon">
              <img src={imgIcon} alt="imogi" />
            </div>
            <div className="text-mid">{isGutPrePostAssessment ? overAllScoreForPrePostAssessment : average}% Your overall score</div>
            {/* <div className="text-mid">{average }% Your overall score</div> */}

            <div className="text-l" style={{ backgroundColor: bgColor }}>
              {result?.meaning}
            </div>
          </div>
        </div>
        <p
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: result?.description }}
        ></p>
        <h2 className="sub-title mt-3 mt-md-5">Your Individual Score</h2>
        <div className="dougnut-charts d-flex mt-4 pt-2">
          {categoryList.map((element) => {
            const questions = answeredQuestionsList.filter(
              (obj) => obj.category === element.id
            );
            
            const totalScore = questions.reduce((acc, obj) => {
              if (obj.isMultipliable) {
                return acc + Number(obj.answer * 2);
              } else if (obj.isMultiselect) {
                return acc + obj.answer.reduce((innerAcc, curr) => innerAcc + Number(curr), 0);
              } else {
                return acc + Number(obj.answer);
              }
            }, 0);
            // const totalScore = questions.reduce(
            //   (acc, obj) => acc + Number(obj.answer),
            //   0
            // );
            // const maxScore = questions.reduce(
            //   (acc, obj) => acc + Number(obj.maxScore),
            //   0
            // );
            const maxScore = questions.reduce((acc, obj) => {
              if (obj.isMultipliable) {
                return acc + Number(obj.maxScore * 2);
              } else if (obj.isMultiselect) {
                return acc + obj.answer.reduce((innerAcc, curr) => innerAcc + Number(curr), 0);
              } else {
                return acc + Number(obj.maxScore);
              }
            }, 0);
            const minScore = questions.reduce(
              (acc, obj) => acc + Number(obj.minScore),
              0
            );
            const overallScore =
              Math.floor(
                ((totalScore - minScore) / (maxScore - minScore)) * 100
              ) || 0;


            return (
              <div className="chart-block text-center" key={element.id}>
                <strong>{element.title}</strong>

                <div className="chart-item mt-3">
                  <span className="num">{overallScore}%</span>

                  <svg width="40" height="40" viewBox="0 0 40 40">
                    <g id="" className="g1" style={{ stroke: element.color }}>
                      <circle cx="20" cy="20" r="15.9" />
                    </g>
                    <g
                      id=""
                      className="g2"
                      style={{ strokeDashoffset: `-${overallScore}` }}
                    >
                      <circle cx="20" cy="20" r="15.9" />
                    </g>
                  </svg>
                </div>
                <button
                  onClick={() => {
                    dispatch({
                      type: SET_CURRENT_STEP,
                      value: currentStep + 1,
                    });
                    dispatch({
                      type: SET_SELECTED_CATEGORY,
                      value: element,
                    });
                  }}
                  className="btn btn-primary-small btn-2 mt-3"
                >
                  view
                </button>
              </div>
            );
          })}
        </div>

        <p className="mt-3 mt-md-5">
          Disclaimer: Please note the information given is not intended to
          diagnose, prescribe, or replace the advice of a doctor or other
          healthcare professionals.
        </p>
      </div>
      <div className="bottom-row d-flex align-items-end mt-4 pt-4 ms-3"></div>
      <div className="row btn-row d-flex justify-content-end mt-2 mt-md-3 pt-1 gap-5">

        <a
          className="btn btn-primary-small btn-2 d-flex align-items-center justify-content-center col-sm-12 col-md-5 col-lg-3 "
          href={`${API_URL}/questions/downloadScore/${userDetail.id}`}
          target="_blank"
          rel="noreferrer"
        >
          Download Your Report
        </a>
        <button
          className="btn btn-primary  btn-2 col-sm-12 col-md-4 col-lg-3 "
          onClick={() =>
            dispatch({ type: SET_CURRENT_STEP, value: currentStep + 2 })
          }
        >
          End Survey
        </button>
      </div>
    </>
  );
};
