import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  API_URL,
  DEFAULT_QUESTIONS_LIMIT,
  DEFAULT_QUESTIONS_OFFEST,
} from "../config/constant";
import {
  SET_ANSWERS,
  SET_CATEGORY,
  SET_CATEGORY_LENGTH,
  SET_CURRENT_STEP,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_CATEGORY_INDEX,
  SET_USER_DETAIL,
} from "../store/actions";
import { Skip } from "./Skip";

export const Questions = () => {
  const dispatch = useDispatch();
  const categoryLength = useSelector((state) => state.categoryLength);
  const selectedCategoryIndex = useSelector(
    (state) => state.selectedCategoryIndex
  );
  const currentStep = useSelector((state) => state.currentStep);
  const categoryList = useSelector((state) => state.categoryList);
  const [count, setCount] = useState(
    categoryList.length === 4
      ? categoryList.length - 2 + 5
      : categoryList.length === 5
        ? categoryList.length - 2 + 5
        : categoryList.length + 3
  );
  // console.log("count",count)
  const selectedCategory = useSelector((state) => state.selectedCategory);
  const allQuestionsList = useSelector((state) => state.questionsList);
  const userFormData = useSelector((state) => state.userFormData);

  const answeredQuestionsList = useSelector(
    (state) => state.answeredQuestionsList
  );
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  // const [totalScore, setTotalScore] = useState();
  // const [values, setValues] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isContinue, setIsContinue] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [questionsList, setQuestionsList] = useState([]);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_QUESTIONS_OFFEST);
  const [answerIndex, setAnswerIndex] = useState([]);
  const isGutPrePostAssessment = useSelector((state) => state.isGutPrePostAssessment);

  const findIndex = answeredQuestionsList.findIndex(
    (a) => a.answer === "0" && a.isAdult === true
  );

  findIndex !== -1 && answeredQuestionsList.splice(findIndex, 1);
  useEffect(() => {
    if (selectedCategoryIndex === null) {
      dispatch({ type: SET_SELECTED_CATEGORY_INDEX, value: 0 });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      onNext();
    }
    // eslint-disable-next-line
  }, [selectedCategory, currentOffset, answerIndex, categoryList]);

  const onNext = async () => {
    let selectedCategoryQuestions = allQuestionsList
      .filter((obj) => obj.category === selectedCategory?.id)
      .map((obj, index) => {
        obj.number = index + 1;
        return obj;
      });
    if (isSkip) {
      selectedCategoryQuestions = [];
    }
    setTotalPages(
      Math.ceil(selectedCategoryQuestions.length / DEFAULT_QUESTIONS_LIMIT)
    );
    setCurrentPage(Math.ceil(currentOffset / DEFAULT_QUESTIONS_LIMIT) + 1);
    const questions = selectedCategoryQuestions.slice(
      currentOffset,
      DEFAULT_QUESTIONS_LIMIT + currentOffset
    );
    var categoryScore = null
    if (questions.length === 0) {
      if (categoryList.length - 1 === selectedCategoryIndex) {
        const individualScore = [];
        categoryList.map((element) => {
          const questions = answeredQuestionsList.filter(
            (obj) => obj.category === element.id
          );
          const totalScore = questions.reduce((acc, obj) => {
            if (obj.isMultipliable) {
              return acc + Number(obj.answer * 2);
            } 
            else if (obj.isMultiselect) {
              return acc + obj.answer.reduce((innerAcc, curr) => innerAcc + Number(curr), 0);
            }
            else {
              return acc + Number(obj.answer);
            }
          }, 0);
          // const maxScore = questions.reduce(
          //   (acc, obj) => acc + Number(obj.maxScore),
          //   0
          // );
          const maxScore = questions.reduce((acc, obj) => {
            if (obj.isMultipliable) {
              return acc + Number(obj.maxScore * 2);
            } else if (obj.isMultiselect) {
              return acc + obj.answer.reduce((innerAcc, curr) => innerAcc + Number(curr), 0);
            } else {
              return acc + Number(obj.maxScore);
            }
          }, 0);

          const minScore = questions.reduce(
            (acc, obj) => acc + Number(obj.minScore),
            0
          );
          const overallScore =
            Math.floor(
              ((totalScore - minScore) / (maxScore - minScore)) * 100
            ) || 0;
          return individualScore.push(overallScore);
        });
     
        categoryScore = individualScore.length
        ? individualScore.reduce((acc, score) => acc + score, 0) / individualScore.length
        : 0;
  
        // totalScore formula changes on 7/02/2022
        const totalScore =
          categoryList.length <= 4
            ? Math.floor(
              (individualScore[3] * 3 +
                individualScore[0] * 2 +
                individualScore[1] +
                individualScore[2]) /
              7
            )
            : categoryList.length === 5
              ? Math.floor(
                (individualScore[3] * 3 +
                  individualScore[0] * 2 +
                  individualScore[1] +
                  individualScore[2] +
                  individualScore[4]) /
                8
              )
              : Math.floor(
                (individualScore[3] * 3 +
                  individualScore[0] * 2 +
                  individualScore[1] +
                  individualScore[2] +
                  individualScore[4] +
                  individualScore[5]) /
                9
              );
console.log("categoryScore rizwan>>>>>", categoryScore);
        const values =
          categoryList.length <= 4
            ? {
              slug: window.location.pathname.split("/")[1],
              userData: userFormData,
              answers: {
                answers: answeredQuestionsList,
                category: categoryList,
                totalScore: isGutPrePostAssessment ? categoryScore : totalScore,
                adequateExercise: individualScore[0],
                balancedNutrition: individualScore[1],
                emotionalDetox: individualScore[2],
                qualitySleep: individualScore[3],
              },
            }
            : categoryList.length === 5
              ? {
                slug: window.location.pathname.split("/")[1],
                userData: userFormData,
                answers: {
                  answers: answeredQuestionsList,
                  category: categoryList,
                  totalScore:  totalScore,
                  adequateExercise: individualScore[0],
                  balancedNutrition: individualScore[1],
                  emotionalDetox: individualScore[2],
                  qualitySleep: individualScore[3],
                  health: individualScore[4],
                },
              }
              : {
                slug: window.location.pathname.split("/")[1],
                userData: userFormData,
                answers: {
                  answers: answeredQuestionsList,
                  category: categoryList,
                  totalScore: isGutPrePostAssessment ? categoryScore : totalScore,
                  adequateExercise: individualScore[0],
                  balancedNutrition: individualScore[1],
                  emotionalDetox: individualScore[2],
                  qualitySleep: individualScore[3],
                  health: individualScore[4],
                  sexualWellness: individualScore[5],
                },
              };

        try {
          setShowSubmitLoader(true);
          const { data } = await axios
            .post(`${API_URL}/questions`, values)
            .then((res) => res.data);
          // console.log("data", data);
          dispatch({
            type: SET_USER_DETAIL,
            value: data?.user || null,
          });
        } catch (e) {
          console.log(e);
        }
        dispatch({
          type: SET_CURRENT_STEP,
          value: currentStep + 1,
        });
        dispatch({
          type: SET_SELECTED_CATEGORY_INDEX,
          value: null,
        });
      } else {
        dispatch({
          type: SET_SELECTED_CATEGORY_INDEX,
          value: selectedCategoryIndex + 1,
        });
        setCurrentPage(1);
        setCurrentOffset(0);
      }
    }
    window.scrollTo(0, 650);
    // const adultQuestions = questions.filter((ele) => {
    //   if (ele.isAdult !== true || ele.answer !== "0") {
    //     return ele;
    //   }
    // });

    setQuestionsList(questions);
  };

  return (
    <>
      <div className="questions-page">
        <div className="title-block d-flex flex-column flex-sm-row align-items-start">
          <div className="d-flex align-items-center text-left ">
            <figure className="title-img">
              <img src={selectedCategory?.image} alt="lc survey" />
            </figure>
            <h1 className="page-title mb-0">
              <span>{selectedCategory?.title}</span>
            </h1>
          </div>
          <div className="que-chart-block ms-auto">
            <div className="chart-block text-center">
              <div className="chart-item">
                <span className="num">{currentPage}</span>
                <svg width="40" height="40" viewBox="0 0 40 40">
                  <g
                    id=""
                    className="g1"
                    style={{ stroke: selectedCategory?.color }}
                  >
                    <circle cx="20" cy="20" r="15.9" />
                  </g>
                  <g
                    id=""
                    className="g2"
                    style={{
                      strokeDashoffset: `-${(currentPage * 100) / totalPages}`,
                    }}
                  >
                    <circle cx="20" cy="20" r="15.9" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* <p className="sub-text">Before we begin, Please fill in your details top proceed</p> */}
        <div className="questions-item-block">
          {selectedCategoryIndex === 5 && isContinue === false && (
            <Skip
              selectedCategoryIndex={selectedCategoryIndex}
              isContinue={isContinue}
              onContinue={() => setIsContinue(true)}
              onSkip={() => {
                dispatch({
                  type: SET_CATEGORY,
                  value: categoryList.filter((_, index) => index !== 5),
                });

                dispatch({
                  type: SET_CATEGORY_LENGTH,
                  value: categoryList.length - 1,
                });
                setIsSkip(true);

                dispatch({
                  type: SET_SELECTED_CATEGORY_INDEX,
                  value: selectedCategoryIndex - 1,
                });

                // dispatch({
                //   type: SET_CATEGORY,
                //   value: categoryList.filter((_, index) => index !== 5),
                // });
                // dispatch({
                //   type: SET_SELECTED_CATEGORY,
                //   value: categoryList[4],
                // });
              }}
            />
          )}

          {questionsList.map((item, index) => {
            return (
              <>
                {answerIndex.includes(index) ? (
                  <div className="question-item que-error" key={item.id}>
                    <div className="question-content">
                      <h2
                        className="question-title"
                        style={{
                          color: selectedCategory?.color || "#46BBC5",
                        }}
                      >
                        {item.number}.{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        ></span>
                      </h2>
                      <div className="d-flex flex-wrap inline mt-2 mt-xxl-3">
                        {item.options.map((option, oIndex) => {
                          return (
                            <div
                              className="form-check me-2 me-lg-3 me-xxl-5"
                              key={option.key}
                            >
                              <input
                                name={item.id}
                                className="form-check-input"
                                type={item.isMultiselect ? "checkbox" : "radio"}
                                id={`${option.key.replace(/\s+/g, " ").trim()}${item.number}${oIndex}`}
                                onChange={(e) => {
                                  if (item.isMultiselect) {
                                    // Handle checkbox change
                                    if (e.target.checked) {
                                      questionsList[index].answer = [
                                        ...(questionsList[index].answer || []),
                                        option.score,
                                      ];
                                    } else {
                                      questionsList[index].answer = (
                                        questionsList[index].answer || []
                                      ).filter((answer) => answer !== option.score);
                                    }
                                  } else {
                                    // Handle radio button change
                                    questionsList[index].answer = option.score;
                                    questionsList[index].answerLabel = option.key;
                                  }
                                  setQuestionsList([...questionsList]); // Update state with a new reference
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`${option.key
                                  .replace(/\s+/g, " ")
                                  .trim()}${item.number}${oIndex}`}
                              >
                                {option.key}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {item.research !== "<p> </p>" && <div className="question-footer d-flex align-items-center">
                      <figure className="footer-img mb-0 me-2 me-xl-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="55.18"
                          height="42.964"
                          viewBox="0 0 55.18 42.964"
                        >
                          <g
                            id="Group_736"
                            data-name="Group 736"
                            transform="translate(-7172.215 -1150.018)"
                          >
                            <path
                              style={{ fill: selectedCategory?.color }}
                              id="Ellipse_411"
                              data-name="Ellipse 411"
                              d="M21.483,0a21.415,21.415,0,1,1-8.362,1.688A21.414,21.414,0,0,1,21.483,0Z"
                              transform="translate(7172.215 1150.018)"
                              fill="#46bbc5"
                            />
                            <path
                              id="HEALTH"
                              d="M5.017,2.782h1.7V9.431h-1.7V6.743H2.3V9.431H.6V2.782H2.3V5.366H5.017Zm2.9,6.649V2.782h4.725V4.121H9.61V5.385h2.848v1.33H9.61V8.092h3.037V9.431Zm10.61,0-.594-1.377H15L14.4,9.431H12.657l3.1-6.649h1.377l3.112,6.649Zm-2.98-2.678h1.83l-.915-2.131Zm5.093,2.678V2.782H22.38V8.026h2.829V9.431Zm5.715,0V4.187H24.37V2.782H30.1V4.187h-1.99V9.431Zm8.875-6.649h1.7V9.431h-1.7V6.743H32.519V9.431h-1.7V2.782h1.7V5.366h2.716Z"
                              transform="translate(7175 1160.449)"
                              fill="#fff"
                            />
                            <path
                              id="FACT"
                              d="M.8,12.575V3.71H6.979V5.52h-3.9V7.155H6.728V8.966H3.081v3.609Zm14.989,0L15,10.739H11.078l-.792,1.836H7.96L12.1,3.71h1.836l4.15,8.865ZM11.82,9h2.44L13.04,6.162Zm12.487,3.684q-1.383,0-2.408-.566t-1.572-1.6q-.547-1.031-.547-2.389t.547-2.383q.547-1.025,1.572-1.591T24.307,3.6q.868,0,1.679.264t1.327.7L26.6,6.2q-1.094-.767-2.238-.767-1.081,0-1.654.679t-.572,2.025q0,1.346.572,2.031t1.654.685q1.144,0,2.238-.767l.717,1.635q-.516.44-1.327.7T24.307,12.688Zm7.734-.113V5.583H29.387V3.71h7.646V5.583H34.38v6.992Z"
                              transform="translate(7175 1168.832)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </figure>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.research }}
                      ></div>
                    </div>
                    }
                  </div>
                ) : (
                  <div className="question-item" key={item.id}>
                    <div className="question-content">
                      <h2
                        className="question-title"
                        style={{
                          color: selectedCategory?.color || "#46BBC5",
                        }}
                      >
                        {item.number}.{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        ></span>
                      </h2>
                      <div className="d-flex flex-wrap inline mt-2 mt-xxl-3">
                        {item.options.map((option, oIndex) => {
                          return (
                            <div
                              className="form-check me-2 me-lg-3 me-xxl-5"
                              key={option.key}
                            >
                              <input
                                name={item.id}
                                className="form-check-input"
                                type={item.isMultiselect ? "checkbox" : "radio"}
                                id={`${option.key.replace(/\s+/g, " ").trim()}${item.number}${oIndex}`}
                                onChange={(e) => {
                                  if (item.isMultiselect) {
                                    // Handle checkbox change
                                    if (e.target.checked) {
                                      questionsList[index].answer = [
                                        ...(questionsList[index].answer || []),
                                        option.score,
                                      ];
                                    } else {
                                      questionsList[index].answer = (
                                        questionsList[index].answer || []
                                      ).filter((answer) => answer !== option.score);
                                    }
                                  } else {
                                    // Handle radio button change
                                    questionsList[index].answer = option.score;
                                    questionsList[index].answerLabel = option.key;
                                  }
                                  setQuestionsList([...questionsList]); // Update state with a new reference
                                }}
                              />

                              <label
                                className="form-check-label"
                                htmlFor={`${option.key
                                  .replace(/\s+/g, " ")
                                  .trim()}${item.number}${oIndex}`}
                              >
                                {option.key}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {item.research !== "<p> </p>" && <div className="question-footer d-flex align-items-center">
                      <figure className="footer-img mb-0 me-2 me-xl-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="55.18"
                          height="42.964"
                          viewBox="0 0 55.18 42.964"
                        >
                          <g
                            id="Group_736"
                            data-name="Group 736"
                            transform="translate(-7172.215 -1150.018)"
                          >
                            <path
                              style={{ fill: selectedCategory?.color }}
                              id="Ellipse_411"
                              data-name="Ellipse 411"
                              d="M21.483,0a21.415,21.415,0,1,1-8.362,1.688A21.414,21.414,0,0,1,21.483,0Z"
                              transform="translate(7172.215 1150.018)"
                              fill="#46bbc5"
                            />
                            <path
                              id="HEALTH"
                              d="M5.017,2.782h1.7V9.431h-1.7V6.743H2.3V9.431H.6V2.782H2.3V5.366H5.017Zm2.9,6.649V2.782h4.725V4.121H9.61V5.385h2.848v1.33H9.61V8.092h3.037V9.431Zm10.61,0-.594-1.377H15L14.4,9.431H12.657l3.1-6.649h1.377l3.112,6.649Zm-2.98-2.678h1.83l-.915-2.131Zm5.093,2.678V2.782H22.38V8.026h2.829V9.431Zm5.715,0V4.187H24.37V2.782H30.1V4.187h-1.99V9.431Zm8.875-6.649h1.7V9.431h-1.7V6.743H32.519V9.431h-1.7V2.782h1.7V5.366h2.716Z"
                              transform="translate(7175 1160.449)"
                              fill="#fff"
                            />
                            <path
                              id="FACT"
                              d="M.8,12.575V3.71H6.979V5.52h-3.9V7.155H6.728V8.966H3.081v3.609Zm14.989,0L15,10.739H11.078l-.792,1.836H7.96L12.1,3.71h1.836l4.15,8.865ZM11.82,9h2.44L13.04,6.162Zm12.487,3.684q-1.383,0-2.408-.566t-1.572-1.6q-.547-1.031-.547-2.389t.547-2.383q.547-1.025,1.572-1.591T24.307,3.6q.868,0,1.679.264t1.327.7L26.6,6.2q-1.094-.767-2.238-.767-1.081,0-1.654.679t-.572,2.025q0,1.346.572,2.031t1.654.685q1.144,0,2.238-.767l.717,1.635q-.516.44-1.327.7T24.307,12.688Zm7.734-.113V5.583H29.387V3.71h7.646V5.583H34.38v6.992Z"
                              transform="translate(7175 1168.832)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31.018"
                          height="27.08"
                          viewBox="0 0 31.018 27.08"
                        >
                          <path
                            style={{ fill: selectedCategory?.color }}
                            d="M803.837-9.746l.474-.39a5.21,5.21,0,0,0,1.881-3.248,4.05,4.05,0,0,0-.792-3.393,3.856,3.856,0,0,0-2.6-1.452,5.35,5.35,0,0,0-4.75,1.691,5.387,5.387,0,0,0-1.36,2.8c-.016.089-.038.177-.066.3-.295-.294-.576-.56-.841-.842a15.277,15.277,0,0,1-3.546-6.125,14.964,14.964,0,0,1-.556-2.677,15.509,15.509,0,0,1-.066-2.895,15.339,15.339,0,0,1,3.038-8.124c.076-.1.134-.111.227-.041a.637.637,0,0,0,.139.08c.132.054.154.131.1.265a4.893,4.893,0,0,0-.4,2.333,4.235,4.235,0,0,0,1.961,3.564,4.2,4.2,0,0,0,2.105.564,4.923,4.923,0,0,0,4.564-2.631,3.025,3.025,0,0,0,.163-2.82,2.367,2.367,0,0,0-1.546-1.368c-.243-.08-.239-.082-.088-.293a3.756,3.756,0,0,1,2.336-1.578,3.542,3.542,0,0,1,4.241,2.7,3.8,3.8,0,0,1-.977,3.4,12.868,12.868,0,0,1-3.218,2.389,11.511,11.511,0,0,0-2.533,1.768,6.639,6.639,0,0,0-1.988,3.177,5.382,5.382,0,0,0-.145,1.854,1.154,1.154,0,0,0,1.145,1.016,28.754,28.754,0,0,0,4.368.038,1.413,1.413,0,0,0,1.04-.621,5.082,5.082,0,0,0,.587-1.117,4.146,4.146,0,0,1,2.047-2.279c.344-.162.7-.3,1.049-.448a18.526,18.526,0,0,0,5.091-3.1,7.452,7.452,0,0,0,2.257-3.7,8.223,8.223,0,0,0,.316-2.9A6.979,6.979,0,0,0,816.987-36a.37.37,0,0,1,.266-.507c.086-.023.12.048.164.088a16.833,16.833,0,0,1,1.619,1.657,15.225,15.225,0,0,1,3.163,6.323,15.606,15.606,0,0,1,.4,3.581,14.861,14.861,0,0,1-1.808,7.188,15.373,15.373,0,0,1-8.11,7.2,15.665,15.665,0,0,1-3.686.949,20.583,20.583,0,0,1-2.736.088A14.713,14.713,0,0,1,804-9.686,1.129,1.129,0,0,1,803.837-9.746Z"
                            transform="translate(-791.577 36.509)"
                          />
                        </svg> */}
                      </figure>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.research }}
                      ></div>
                    </div>
                    }
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
      <div className="btn-row d-flex justify-content-end mt-2 mt-md-3 pt-1">
        <button
          className="btn btn-primary btn-2"
          disabled={showSubmitLoader}
          onClick={() => {
            const checkAnswers = questionsList.filter((obj) => obj.answer);
            const answerId = checkAnswers.map((ele) => ele.id);
            const aIndex = [];
            if (checkAnswers.length !== questionsList.length) {
              questionsList.forEach((element, index) => {
                if (!answerId.includes(element.id)) {
                  aIndex.push(index);
                  setAnswerIndex(aIndex);
                }
              });
              return;
            }
            dispatch({ type: SET_ANSWERS, value: questionsList });
            setCurrentOffset(currentOffset + DEFAULT_QUESTIONS_LIMIT);
            setAnswerIndex([]);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};
